import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"testimonials"} />
		<Helmet>
			<title>
				Erfahrungsberichte | Ritixye Autoservice und Reifenmontage
			</title>
			<meta name={"description"} content={"Unsere Autowerkstatt ist Ihr zuverlässiger Partner in der Welt der Autowartung!"} />
			<meta property={"og:title"} content={"Erfahrungsberichte | Ritixye Autoservice und Reifenmontage"} />
			<meta property={"og:description"} content={"Unsere Autowerkstatt ist Ihr zuverlässiger Partner in der Welt der Autowartung!"} />
			<meta property={"og:image"} content={"https://ritixye.com/img/banner.jpeg"} />
			<link rel={"shortcut icon"} href={"https://ritixye.com/img/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ritixye.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ritixye.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ritixye.com/img/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ritixye.com/img/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ritixye.com/img/icon.png"} />
			<meta name={"msapplication-TileColor"} content={"https://ritixye.com/img/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 56px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--headline1"
					text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Erfahrungsberichte
				</Text>
				<Text margin="0px 0px 0px 0px" color="--grey" text-align="center" font="--lead">
					Die beste Qualität zeigt sich an zufriedenen Kunden.
				</Text>
			</Box>
			<Box
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="16px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
			>
				<Box
					padding="50px 55px 50px 55px"
					sm-padding="55px 40px 50px 55px"
					border-width="1px"
					border-style="solid"
					border-radius="24px"
					border-color="--color-lightD2"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Text
						margin="0px 0px 35px 0px"
						color="--dark"
						font="--lead"
						lg-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 30px 0px"
						flex="1 0 auto"
					>
						Ich habe meinen Wagen schon seit Jahren bei Ritixye Service Center in Wartung und bin immer zufrieden. Sie haben nicht nur eine Gasanlage in meinem Auto installiert, sondern führen auch regelmäßig Wartungsarbeiten durch.
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start" flex-direction="column">
						<Box>
							<Text color="--dark" font="normal 800 25px/1.5 --fontFamily-googleSourceSansPro" margin="0px 0px 2px 0px">
								Irina M.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					padding="50px 55px 50px 55px"
					sm-padding="55px 40px 50px 55px"
					border-width="1px"
					border-style="solid"
					border-radius="24px"
					border-color="--color-lightD2"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Text
						margin="0px 0px 35px 0px"
						color="--dark"
						font="--lead"
						lg-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 30px 0px"
						flex="1 0 auto"
					>
						Vielen Dank an das Ritixye Service Center für den ausgezeichneten Service! Die Installation einer Gasanlage war ein großer wirtschaftlicher Schritt für mich, und dank ihrer Fachleute verlief sie reibungslos. Ich habe auch ihre Serviceleistungen für die regelmäßige Wartung meines Autos genutzt und war immer beeindruckt von ihrer Liebe zum Detail.
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start" flex-direction="column">
						<Box>
							<Text color="--dark" font="normal 700 25px/1.5 --fontFamily-googleSourceSansPro" margin="0px 0px 2px 0px">
								Andreas H.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					padding="50px 55px 50px 55px"
					sm-padding="55px 40px 50px 55px"
					border-width="1px"
					border-style="solid"
					border-radius="24px"
					border-color="--color-lightD2"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Text
						margin="0px 0px 35px 0px"
						color="--dark"
						font="--lead"
						lg-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 30px 0px"
						flex="1 0 auto"
					>
						Ich habe mich entschieden, zum ersten Mal eine Gasanlage in meinem Auto zu installieren, und war ein wenig besorgt. Das Ritixye Service Center wurde mir von Freunden empfohlen, und ich wurde nicht enttäuscht. Sie haben die Installation professionell durchgeführt und mich anschließend darin geschult, wie man dieses System richtig verwendet. Jetzt spart mein Auto Kraftstoff, und ich bin mehr als zufrieden mit den Ergebnissen.
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start" flex-direction="column">
						<Box>
							<Text color="--dark" font="normal 700 25px/1.5 --fontFamily-googleSourceSansPro" margin="0px 0px 2px 0px">
								Adriana B.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					padding="50px 55px 50px 55px"
					sm-padding="55px 40px 50px 55px"
					border-width="1px"
					border-style="solid"
					border-radius="24px"
					border-color="--color-lightD2"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Text
						margin="0px 0px 35px 0px"
						color="--dark"
						font="--lead"
						lg-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 30px 0px"
						flex="1 0 auto"
					>
						Als mein Auto eine ernsthafte Reparatur benötigte, wandte ich mich an das Ritixye Service Center und bereue es nicht. Sie erwiesen sich als hochqualifiziertes Team, das das Problem schnell löste und mein Auto wie neu machte. Jetzt ist es meine Nummer-eins-Anlaufstelle für Service.
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start" flex-direction="column">
						<Box>
							<Text color="--dark" font="normal 700 25px/1.5 --fontFamily-googleSourceSansPro" margin="0px 0px 2px 0px">
								Maximilian K.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					padding="50px 55px 50px 55px"
					sm-padding="55px 40px 50px 55px"
					border-width="1px"
					border-style="solid"
					border-radius="24px"
					border-color="--color-lightD2"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Text
						margin="0px 0px 35px 0px"
						color="--dark"
						font="--lead"
						lg-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 30px 0px"
						flex="1 0 auto"
					>
						Das Ritixye Service Center hat mich wirklich beeindruckt! Die Fachleute dort verstehen ihr Handwerk, und ich war sehr zufrieden mit der Qualität ihrer Arbeit. Meine Auto-Reparatur verlief reibungslos, und ich kann diesen Service nur weiterempfehlen.
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start" flex-direction="column">
						<Box>
							<Text color="--dark" font="normal 700 25px/1.5 --fontFamily-googleSourceSansPro" margin="0px 0px 2px 0px">
								Sabine F.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					padding="50px 55px 50px 55px"
					sm-padding="55px 40px 50px 55px"
					border-width="1px"
					border-style="solid"
					border-radius="24px"
					border-color="--color-lightD2"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Text
						margin="0px 0px 35px 0px"
						color="--dark"
						font="--lead"
						lg-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 30px 0px"
						flex="1 0 auto"
					>
						Ich war auf der Suche nach einem vertrauenswürdigen Ort für die Wartung meines Autos und fand das Ritixye Service Center. Ihre freundlichen Mitarbeiter und ihre professionelle Arbeitsweise haben mich überzeugt. Ich bin sehr froh, sie gefunden zu haben, und werde auf jeden Fall wiederkommen.
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start" flex-direction="column">
						<Box>
							<Text color="--dark" font="normal 700 25px/1.5 --fontFamily-googleSourceSansPro" margin="0px 0px 2px 0px">
								Michaela S.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});